import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import WordSwiper from "../Hero/WordSwiper";

const LogoWrap = styled.div`
  margin: auto 0;
  flex: 0 1 250px;

  @media (max-width: 768px) {
    max-height: 50vh;
    margin: 0px;
    flex: 0 1 50px;
  }
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const H1Wrap = styled.h1`
  margin-bottom: 0px;
  color: hsla(0, 0%, 0%, 0.5);
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const ImgWrap = styled(Img)`
  @media (max-width: 768px) {
    max-height: 30vh;
    max-width: 30vh;
    display: flex;
    margin: auto;
  }
`;

const HeroImageTextContent = ({ image, title, subtexts }) => {
  return (
    <>
      <LogoWrap>
        <ImgWrap fluid={image} />
      </LogoWrap>
      <TextWrap>
        <H1Wrap>{title}</H1Wrap>
        <WordSwiper texts={subtexts} />
      </TextWrap>
    </>
  );
};

export default HeroImageTextContent;
