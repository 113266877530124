import React from "react";
import styled from "styled-components";

const HeaderWrap = styled.header`
  display: flex;
  flex-direction: row;
  min-height: 90vh;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Hero = ({ children }) => {
  return <HeaderWrap>{children}</HeaderWrap>;
};

export default Hero;
