import React from "react";
import Hero from "../components/Hero/Hero";
import HeroImageTextContent from "../components/Hero/HeroImageTextContent";
import SEO from "../components/SEO/SEO";

export default function Home({ data }) {
  return (
    <div>
      <SEO title="Alex Dhaenens" url="http://alexdhaenens.com" />
      <Hero>
        <HeroImageTextContent
          image={data.file.childImageSharp.fluid}
          title="Alex Dhaenens"
          subtexts={["Public speaker", "Teacher", "Software engineer"]}
        />
      </Hero>
    </div>
  );
}

export const query = graphql`
  query {
    file(name: { eq: "alex" }, extension: { eq: "jpg" }) {
      childImageSharp {
        fluid(maxWidth: 250, pngQuality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
