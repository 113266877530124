import React from "react";
import styled from "styled-components";

const DivWrap = styled.div`
  overflow: hidden;
  position: relative;
  float: right;
  height: 65px;
  padding-top: 10px;
  margin-top: -10px;
`;

const LiWrap = styled.li`
  padding: 0 10px;
  height: 45px;
  margin-bottom: 45px;
  display: block;
  @media (min-width: 768px) {
    padding-left: 0px;
  }
`;

const UlWrap = styled.ul`
  margin-left: 0px;

  animation: flip3 8s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
  @keyframes flip3 {
    0% {
      margin-top: -270px;
    }
    5% {
      margin-top: -180px;
    }
    33% {
      margin-top: -180px;
    }
    38% {
      margin-top: -90px;
    }
    66% {
      margin-top: -90px;
    }
    71% {
      margin-top: 0px;
    }
    99.99% {
      margin-top: 0px;
    }
    100% {
      margin-top: -270px;
    }
  }
`;

const WordSwiper = ({ texts }) => {
  return (
    <DivWrap>
      <UlWrap>
        {texts.map(s => (
          <LiWrap key={s}>{s}</LiWrap>
        ))}
      </UlWrap>
    </DivWrap>
  );
};

export default WordSwiper;
